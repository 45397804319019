import React, { useState } from 'react';

import { DynamicPricingBanner } from 'inv-common-components';
import { useSelector } from 'react-redux';

export function DynamicPriceAnnouncement() {
  const [isAnnouncementBannerVisible, setIsAnnouncementBannerVisible] = useState(true);
  const isVip = useSelector((state) => state.invReactCommon.isVIP);

  if (localStorage.getItem('DynamicPriceBanner') !== 'false') {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div>
        <DynamicPricingBanner
          isBannerOpen={isAnnouncementBannerVisible}
          setIsBannerOpen={setIsAnnouncementBannerVisible}
          onBannerClose={() => localStorage.setItem('DynamicPriceBanner', 'false')}
          isVip={isVip}
        />
      </div>
    );
  }

  return null;
}

export default DynamicPriceAnnouncement;
