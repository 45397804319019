/* eslint-disable no-undef */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Modal,
  ModalBody,
} from 'reactstrap';
import { get } from 'lodash';

function UserModal({ isModalOpen, closeModal, userCounts }) {
  const firstName = useSelector((state) => get(state, 'invReactCommon.sessionInfo.firstName', ''));
  const isVip = useSelector((state) => get(state, 'invReactCommon.isVIP', false));

  return (
    <Modal
      isOpen={isModalOpen}
      modalClassName="modal-sliding fade right show"
      className="modal-dialog"
      size="sm"
    >
      <div className="modal-header">
        Hi, {firstName}
        <button type="button" className="close" data-dismiss="modal" onClick={() => closeModal()}>
          <span>×</span><span className="sr-only">Close</span>
        </button>
      </div>
      <ModalBody>
        <div className="my-acct-nav menu-options menu-user-options">
          <div className="border-bottom mt-2 pb-2">
            <a id="myInvHomePartial" href="/my-account/home">My Invaluable</a>
          </div>
          <div className="border-bottom mt-2 pb-2">
            <ul className="menu-options-list list-unstyled mb-0">
              <li>
                <a href="/my-account/bids">
                  Bids{userCounts.ACTIVE_BIDS > 0 && ` (${userCounts.ACTIVE_BIDS})`}
                </a>
              </li>
              <li>
                <a href="/my-account/auctions">Auctions
                  <span id="count-auctions" className="inline-block counts">
                    {userCounts.REGISTERED_AUCTIONS > 0 && ` (${userCounts.REGISTERED_AUCTIONS})`}
                  </span>
                </a>
              </li>
              <li>
                <a href="/my-account/purchases">Purchases
                  <span id="count-purchases" className="inline-block counts">
                    {userCounts.WON_LOTS > 0 && ` (${userCounts.WON_LOTS})`}
                  </span>
                </a>
              </li>
              {isVip
                && (
                  <li>
                    <a href="https://www.invaluable.com/inv/vip/" target="_blank" rel="noopener noreferrer">
                      <img
                        width="20"
                        src="https://www.invaluable.com/content/images/VIPBidder_Paddle.svg"
                        alt="VIP Badge"
                      />&nbsp;VIP Benefits
                    </a>
                  </li>
                )}
            </ul>
          </div>
          <div className="border-bottom mt-2 pb-2">
            <ul className="list-unstyled mb-0">
              <li className="no-wrap">
                <a href="/my-account/saved-items">
                  <i className="fa fa-heart mr-1" />
                  Saved Items
                  <span id="count-saved" className="counts">
                    {userCounts.WATCHED_LOTS > 0 && ` (${userCounts.WATCHED_LOTS})`}
                  </span>
                </a>
              </li>
              <li>
                <span className="disabled disabled-link">Following</span>
              </li>
              <li className="ml-3 no-wrap">
                <a href="/my-account/keywords">Keywords
                  <span id="count-keywords" className="counts">
                    {userCounts.KEYWORD_ALERTS > 0 && ` (${userCounts.KEYWORD_ALERTS})`}
                  </span>
                </a>
              </li>
              <li className="ml-3 no-wrap">
                <a href="/my-account/artists">Artists
                  <span id="count-artists" className="counts">
                    {userCounts.ARTIST_ALERTS > 0 && ` (${userCounts.ARTIST_ALERTS})`}
                  </span>
                </a>
              </li>
              <li className="ml-3 no-wrap">
                <a href="/my-account/sellers">
                  Sellers
                  <span id="count-sellers" className="counts">
                    {userCounts.FOLLOWING_SELLERS_COUNT > 0 && ` (${userCounts.FOLLOWING_SELLERS_COUNT})`}
                  </span>
                </a>
              </li>
              <li className="ml-3 no-wrap">
                <a href="/my-account/categories">Categories
                  {userCounts.CATEGORY_ALERTS > 0 && ` (${userCounts.CATEGORY_ALERTS})`}
                </a>
              </li>
            </ul>
          </div>
          <div className="border-bottom mt-2 pb-2">
            <ul className="list-unstyled mb-0">
              <li>
                <a href="/my-account/digital-gallery">Digital Gallery</a>
              </li>
            </ul>
          </div>
          <div className="border-bottom mt-2 pb-2">
            <ul className="list-unstyled mb-0">
              <li>
                <a href="/my-account/message-center">Messages
                  {userCounts.UNREAD_MESSAGES_COUNT > 0 && ` (${userCounts.UNREAD_MESSAGES_COUNT})`}
                </a>
              </li>
              <li><a href="/inv/help/">Help</a></li>
              <li><a href="/my-account/profile">Edit Profile</a></li>
              <li><a href="/my-account/email-preferences">Notification &amp;<br /> Email Preferences</a></li>
              <li><a href="/myInvaluable/mySubscriptions.cfm">Subscriptions</a></li>
              <li><a href="/my-account/payment-options">Payment Options</a></li>
            </ul>
          </div>
          <div className="mt-2 mb-5">
            <ul className="list-unstyled">
              <li><a className="logout-link" href="/logout.cfm">Sign Out</a></li>
            </ul>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default UserModal;
