import React, { useState, Fragment, useRef, useEffect, memo } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';

import { saveRecentItem } from '../config';
import SuggestionDropdown from './SuggestionDropdown';
import MobileSuggestionDropdown from './MobileSuggestionDropdown';
import Cross from './components/icons/Cross';
import { CloseButton, SearchBarWrapper } from './styles';

function AutoCompleteSearchBar({ currentRefinement, refine, hits, isMobile }) {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(currentRefinement);

  const triggerClickOnInput = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const onChange = (event) => {
    const val = event.currentTarget.value;
    setValue(val);
    refine(val);
    if (!val) {
      // triggering click on input when its empty to
      // trigger re-render of evergage campaign
      triggerClickOnInput();
    }
  };

  const onClick = () => {
    const trimVal = value.trim();
    if (trimVal) {
      saveRecentItem(trimVal);
      window.location.href = `/search?query=${trimVal}&keyword=${trimVal}`;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  const onOpenDropDown = () => {
    const signupContainer = document.getElementsByClassName('log-signup-container');
    if (signupContainer && !isMobile) {
      signupContainer[0].classList.add('d-none');
      signupContainer[0].classList.add('hide');
      signupContainer[0].classList.remove('d-flex');
    }
    setIsOpen(true);
  };

  const onClickClose = () => {
    const signupContainer = document.getElementsByClassName('log-signup-container');
    if (signupContainer && !isMobile) {
      signupContainer[0].classList.remove('d-none');
      signupContainer[0].classList.remove('hide');
      signupContainer[0].classList.add('d-flex');
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let keyword = urlParams.get('keyword');
    if (keyword) {
      keyword = decodeURIComponent(keyword);
      setValue(keyword);
      refine(keyword);
    }
  }, []);

  return (
    <Fragment>
      <SearchBarWrapper
        id="search-container"
        role="combobox"
        for="search-input"
        aria-haspopup="listbox"
        aria-owns="suggestionDropdown"
        aria-controls="suggestionDropdown"
        aria-expanded="true"
        className={`search-container ${isOpen && !isMobile ? 'expended' : ''}`}
      >
        <a
          href="/catalog/advancedSearch.cfm?srchScope=u"
          className="notranslate subtle-gray"
        >Advanced
        </a>
        <div className="search-input">
          <i className="fa fa-search" />
          <input
            id="search-input"
            aria-label="Search by item, artists, sellers, or category"
            autoComplete="off"
            aria-autocomplete="list"
            aria-controls="suggestionDropdown"
            onChange={onChange}
            onClick={onOpenDropDown}
            onFocus={onOpenDropDown}
            type="text"
            value={currentRefinement}
            placeholder="Search by item, artists, sellers, or category"
            onKeyDown={handleKeyDown}
            ref={ref}
          />
          {isOpen
                    && (
                    <Fragment>
                      <button
                        id="search-go-btn"
                        onClick={onClick}
                        tabIndex="-1"
                        type="button"
                      >
                        <i
                          id="arrow-right"
                          className="fa fa-arrow-right"
                        />
                      </button>
                      {!isMobile && (
                      <CloseButton
                        aria-label="Close suggestion dropdown"
                        id="search-close-btn"
                        onClick={onClickClose}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            onClickClose();
                          }
                        }}
                      >
                        <Cross />
                      </CloseButton>
                      )}
                      {
                            isMobile && (
                            <MobileSuggestionDropdown
                              value={value}
                              hits={hits}
                              isMobile={isMobile}
                              onClickOutside={() => {
                                if (isMobile) {
                                  onClickClose();
                                }
                              }}
                            />
                            )
                        }
                    </Fragment>
                    )}
        </div>
      </SearchBarWrapper>
      {isOpen && !isMobile && (
      <SuggestionDropdown
        value={value}
        hits={hits}
        onClickOutside={onClickClose}
      />
      )}
    </Fragment>
  );
}

export default memo(connectAutoComplete(AutoCompleteSearchBar));
