export const MY_INVALUABLE_VERSIONS = {
  legacy: 'old',
  version1: 'v1',
  version2: 'v2'
};

export const REQUEST_URLS = [
  '/api/members/artists/followed',
  '/api/members/auctions',
  '/api/members/bids?upcoming=true&size=20',
  '/api/members/savedLots?page=0&upcoming=1&sort=savedDate,desc'
];
