import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { selectSessionInfo, selectAuthToken } from 'src/selectors/inv-common';
import INVCommonService from 'src/services/common';
import { openLoginModal } from 'src/utils/common/helpers';
import {
  POST_LOGIN_ACTION_TYPE,
  fetchPostLoginAction,
  addPostLoginAction,
  removePostLoginAction
} from 'src/utils/post-login';

import { ERROR_MESSAGES, WATCH_UNWATCH_LOT_ACTIONS } from './constants';
import ErrorModal from './ErrorModal';

function WatchUnwatchLot({ lotRef, isWatched }) {
  const [isLotWatched, setIsLotWatched] = useState(isWatched);
  const [errorMessage, setErrorMessage] = useState('');
  const { isLoggedIn } = useSelector(selectSessionInfo);
  const xAuthToken = useSelector(selectAuthToken);

  const isShowErrorModal = !!errorMessage;
  const heartIconClasses = classNames(['fa',
    {
      'fa-heart': isLotWatched,
      'fa-heart-o': !isLotWatched,
    },
  ]);
  const addToWatchedLotButtonClasses = classNames(['circle-btn', 'add-fav',
    {
      fill: isLotWatched,
    },
  ]);

  const handleWatchUnwatchLotItem = async () => {
    const action = isLotWatched ? WATCH_UNWATCH_LOT_ACTIONS.UNWATCH_LOT : WATCH_UNWATCH_LOT_ACTIONS.WATCH_LOT;
    const { success } = await INVCommonService.postWatchUnWatchLot(lotRef, action, xAuthToken);

    if (success) {
      setIsLotWatched(prevState => !prevState);
    } else {
      const formattedErrorMsg = isLotWatched
        ? ERROR_MESSAGES.UN_WATCH_LOT_ERROR : ERROR_MESSAGES.WATCH_LOT_ERROR;
      setErrorMessage(formattedErrorMsg);
    }
  };

  const resetErrorMessage = () => setErrorMessage('');

  useEffect(() => {
    if (!isLoggedIn) return;

    const { hasPostLoginAction, postLoginAction } = fetchPostLoginAction();

    if (
      hasPostLoginAction
      && postLoginAction.includes(lotRef)
      && postLoginAction.includes(POST_LOGIN_ACTION_TYPE.ADD_TO_WATCHED_LOTS)
    ) {
      !isLotWatched && handleWatchUnwatchLotItem();
      removePostLoginAction();
    }
  }, [isLoggedIn]);

  const handleUpdateWatchedLotsList = () => {
    if (!isLoggedIn) {
      addPostLoginAction({ type: POST_LOGIN_ACTION_TYPE.ADD_TO_WATCHED_LOTS, payload: lotRef });
      openLoginModal();

      return;
    }

    handleWatchUnwatchLotItem();
  };

  return (
    <Fragment>
      <button
        onClick={handleUpdateWatchedLotsList}
        className={addToWatchedLotButtonClasses}
        aria-label="Add to Watched Lots"
      >
        <i className={heartIconClasses} />
      </button>
      <ErrorModal errorMessage={errorMessage} isShowErrorModal={isShowErrorModal} toggleModal={resetErrorMessage} />
    </Fragment>
  );
}

WatchUnwatchLot.propTypes = {
  lotRef: PropTypes.string.isRequired,
  isWatched: PropTypes.bool,
};

WatchUnwatchLot.defaultProps = {
  isWatched: false,
};

export default WatchUnwatchLot;
