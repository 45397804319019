/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { HideMd } from 'src/containers/Header/Header.styled';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { MODAL_NAMES } from 'src/utils/constants';
import { setModalStatus } from 'src/actions/inv-common.actions';
import { initGoogleSso } from './modals/loginUtils';

function LoggedOutMenu() {
  const isMobile = useSelector((state) => get(state, 'invReactCommon.isMobile', false));
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));
  const dispatch = useDispatch();

  const openLogin = () => dispatch(setModalStatus({ name: MODAL_NAMES.login, isOpen: true }));
  const openSignup = () => dispatch(setModalStatus({ name: MODAL_NAMES.signup, isOpen: true }));

  useEffect(() => {
    initGoogleSso(isProd, false);
  }, []);

  return (
    <div className="signup-col-container pull-right" id="signup-buttons-ctn">
      <div className="log-signup-container d-flex">
        <a
          id="headerHeartIcon"
          className="btn btn-link saved-items text-nowrap"
          role="button"
          aria-label="Saved Items"
          onClick={openLogin}
        >
          <span className="fa fa-heart" />
          <HideMd>&nbsp;Saved</HideMd>
        </a>
        <div className="d-inline-block text-nowrap mt-sm-1 mt-md-0">
          <button
            id="notifLoginBtn"
            type="button"
            className="btn btn-transparent"
            onClick={openLogin}
            aria-label="open login modal"
          >
            <span className={`fa fa-bell ${isMobile ? 'header-bell' : ''}`} />
            <HideMd>
              &nbsp;Notifications
            </HideMd>
          </button>
          <a
            type="button"
            className="btn btn-transparent login-link notranslate"
            onClick={openLogin}
            id="loginLink"
          >
            Log In
          </a>
          <a
            type="button"
            className="btn btn-default d-none d-md-inline-block notranslate create-acct-link"
            id="signUpLink"
            onClick={openSignup}
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoggedOutMenu;
