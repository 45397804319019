import React, { Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { FacebookShareButton, TwitterShareButton, PinterestShareButton, EmailShareButton } from 'react-share';

import NoSSR from '../../../hoc/NoSSR';
import { selectArtistDisplayName } from '../../../selectors/artist-detail';
import { SHARE_TOOLTIP_HIDE_DELAY } from './constants';

export function SocialShare() {
  const shareIconRef = useRef(null);
  const artistName = useSelector(selectArtistDisplayName);
  const artistDetailUrl = typeof window === 'undefined' ? '' : window.location.href;

  const handlePrintArtistClick = () => {
    window.print();
  };

  return (
    <Fragment>
      <a className="circle-btn" ref={shareIconRef} alt="share all">
        <i className="fa fa-share-alt" />
      </a>
      <NoSSR>
        <UncontrolledTooltip
          className="custom-block-tooltip icons-style"
          placement="bottom"
          target={shareIconRef}
          autohide={false}
          delay={{ hide: SHARE_TOOLTIP_HIDE_DELAY }}
        >
          <FacebookShareButton
            className="circle-btn"
            quote={artistName}
            url={artistDetailUrl}
            aria-label="Share on Facebook"
          >
            <i className="fa fa-facebook" />
          </FacebookShareButton>
          <PinterestShareButton
            className="circle-btn"
            description={artistName}
            url={artistDetailUrl}
            media="thumbnail"
            aria-label="Share on Pinterest"
          >
            <i className="fa fa-pinterest" />
          </PinterestShareButton>
          <TwitterShareButton
            className="circle-btn"
            title={artistName}
            url={artistDetailUrl}
            aria-label="Share on Twitter"
          >
            <i className="fa fa-twitter" />
          </TwitterShareButton>
          <EmailShareButton
            subject={artistName}
            body={artistDetailUrl}
            url={artistDetailUrl}
            className="circle-btn"
            aria-label="Share via Email"
          >
            <i className="fa fa-envelope-o" />
          </EmailShareButton>
          <button
            type="button"
            className="circle-btn"
            onClick={handlePrintArtistClick}
            aria-label="Print this Page"
          >
            <i className="fa fa-print" />
          </button>
        </UncontrolledTooltip>
      </NoSSR>
    </Fragment>
  );
}
