import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { FullScreenDialog } from 'inv-common-components';

import { HideMd, HideSm, ShowMobile, HeaderBell } from 'src/containers/Header/Header.styled';
import { fetchVipStatus, fetchUserCounts, fetchNotifications } from 'src/actions/inv-common.actions';
import UserModal from './modals/UserModal';
import UserPopover from './popovers/UserPopover';
import MyInvaluableV1 from './myInvaluable/index';
import { MY_INVALUABLE_VERSIONS } from './myInvaluable/constants';
import NotifiicationsPopover from './popovers/NotificationsPopover';
import NotificationsModal from './modals/NotificationsModal';

function LoggedInMenu() {
  const dispatch = useDispatch();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isNotifsModalOpen, setIsNotifsModalOpen] = useState(false);

  const isMobile = useSelector((state) => get(state, 'invReactCommon.isMobile', false));
  const firstName = useSelector((state) => get(state, 'invReactCommon.sessionInfo.firstName', 'false'));
  const headers = useSelector((state) => get(state, 'invReactCommon.reqHeaders', {}));
  const userId = useSelector((state) => get(state, 'invReactCommon.sessionInfo.memberId', 0));
  const xAuthToken = useSelector((state) => get(state, 'invReactCommon.sessionInfo.authToken', ''));
  const notifCount = useSelector((state) => get(state, 'invReactCommon.notificationsCount', 0));
  const userCounts = useSelector((state) => get(state, 'invReactCommon.counts', {}));
  const myInvaluableVersion = useSelector((state) => get(state, 'invReactCommon.myInvaluableCookie', MY_INVALUABLE_VERSIONS.legacy));
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);

  useEffect(() => {
    dispatch(fetchVipStatus({ headers }));
    dispatch(fetchUserCounts({ headers }));
    dispatch(fetchNotifications({ headers }));
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isFirstTime = get(window, 'sessionStorage.isFirstTimeLogin', 'false');
    const isOnboardingURL = (urlParams.get('onboarding') || sessionStorage.getItem('onboarding')) === 'true';

    if (isFirstTime === 'true' || isOnboardingURL) {
      setIsFirstTimeLogin(true);
      // Remove flag in session storge we only want user to see this off initial signup or with onboarding flag
      window.sessionStorage.removeItem('isFirstTimeLogin');
      window.sessionStorage.removeItem('onboarding');
    }
  }, []);

  const getMyInvaluableContainer = () => {
    switch (myInvaluableVersion) {
      case MY_INVALUABLE_VERSIONS.version1: {
        return (
          <div className="hello-container">
            <a
              href="/my-account/home"
              className="logged-in-link btn"
              id="myInvaluablePopover"
            >
              <span id="headerUserIcon" className="fa fa-user" />
              {firstName
              && <span className="ml-1 user-name notranslate">&nbsp;{firstName}</span>}
            </a>
            <MyInvaluableV1
              userCounts={userCounts}
              firstName={firstName}
              version={myInvaluableVersion}
            />
          </div>
        );
      }
      case MY_INVALUABLE_VERSIONS.version2: return (
        <div className="hello-container">
          <MyInvaluableV1 version={myInvaluableVersion} />
          <a
            href="/my-account/home"
            className="logged-in-link btn"
            id="userOptionsBtn"
          >
            <span id="headerUserIcon" className="fa fa-user" />
            {firstName
              && <span className="ml-1 user-name notranslate">&nbsp;{firstName}</span>}
          </a>
          <UserPopover userCounts={userCounts} />
        </div>
      );
      default: return (
        <div className="hello-container">
          <a
            href="/my-account/home"
            className="logged-in-link btn"
            id="userOptionsBtn"
          >
            <span id="headerUserIcon" className="fa fa-user" />
            {firstName
              && <span className="ml-1 user-name notranslate">&nbsp;{firstName}</span>}
          </a>
          <UserPopover userCounts={userCounts} />
        </div>
      );
    }
  };

  return (
    <div className="hello-container pull-right">
      <a id="headerHeartIcon" className="btn btn-link saved-items text-nowrap" role="button" href="/my-account/saved-items" aria-label="Saved Items">
        <span className="fa fa-heart" />
        <HideMd>&nbsp;Saved</HideMd>
      </a>
      <button
        id="notifLoginBtn"
        type="button"
        className="btn btn-transparent"
        onClick={() => setIsNotifsModalOpen(true)}
        aria-label={`${notifCount > 0 ? `${notifCount} Notifications` : ''}`}
      >
        {notifCount > 0
          ? (
            <>
              <span
                className="fa fa-bell header-bell"
              />
              <span className="notif-badge">{notifCount}</span>
            </>
          )
          : (
            <HeaderBell className="fa fa-bell header-bell" />
          )}
        <HideMd>
        &nbsp;Notifications
        </HideMd>
      </button>
      {!isMobile && (
      <NotifiicationsPopover />
      )}
      <HideSm>
        {getMyInvaluableContainer()}
      </HideSm>
      <ShowMobile>
        <button
          type="button"
          className="logged-in-link btn"
        >
          <span
            className="fa fa-user"
            role="button"
            onClick={() => setIsUserModalOpen(true)}
          />
        </button>
      </ShowMobile>
      <div id="loggedInFlyout" className="logged-in-link-flyout d-none" />
      <UserModal isModalOpen={isUserModalOpen && isMobile} closeModal={() => setIsUserModalOpen(false)} userCounts={userCounts} />
      <NotificationsModal isModalOpen={isNotifsModalOpen && isMobile} closeModal={() => setIsNotifsModalOpen(false)} />
      { isFirstTimeLogin
      && (
      <FullScreenDialog
        openModal={isFirstTimeLogin}
        memberID={userId}
        xAuthToken={xAuthToken}
      />
      )}
    </div>
  );
}

export default LoggedInMenu;
