/* eslint-disable no-console */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import moment from 'moment';
import { sortBy, indexOf, get } from 'lodash';
import axios from 'axios';
import algoliasearch from 'algoliasearch';
import { CallEnum } from '@algolia/transporter';

import logger from 'routes/middleware/logging/logger';

const promiseCache = new Map();

export const formatCurrency = currency => currency && currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// INV-10409 We want to avoid confussion with non-USD currencies that use "$" as well
export const formatMoneyAmount = ({ amount, currencySymbol, currencyCode }) => {
  if (currencySymbol === '$' && currencyCode !== 'USD') {
    return `${formatCurrency(amount)} ${currencyCode}`;
  }

  return `${currencySymbol}${formatCurrency(amount)}`;
};

export const pastSearchDateFilterOptions = [
  { label: 'All dates', start: undefined, end: undefined },
  { label: 'Last 7 days', start: moment().subtract(7, 'day'), end: moment() },
  { label: 'Last 30 days', start: moment().subtract(30, 'day'), end: moment() },
  { label: 'Last 3 months', start: moment().subtract(3, 'month'), end: moment() },
  { label: 'Custom', start: undefined, end: undefined },
];

export const upcomingSeachDateFilterOptions = [
  { label: 'All dates', start: undefined, end: undefined },
  { label: 'Next 7 days', start: moment(), end: moment().add(7, 'days') },
  { label: 'Next 30 days', start: moment(), end: moment().add(1, 'months') },
  { label: 'Next 60 days', start: moment(), end: moment().add(2, 'months') },
  { label: 'Custom', start: undefined, end: undefined },
];

export const filtersOrder = ['hasImage', 'supercategoryName', 'artistName', 'dateTimeUTCUnix', 'houseName', 'countryName', 'currencyCode', 'priceResult', 'currentBid'];

export const sortSelectedFilters = (filters, order) => {
  const orders = sortBy(filters, (item) => {
    // this is edge case as we don't know the order value for inner categories so it always going to be -1
    // and it should be in category order thats why added to category index
    if (indexOf(order, item.attribute) === -1) {
      return order.indexOf('supercategoryName');
    }
    return indexOf(order, item.attribute);
  });
  return orders;
};

export const convertTimeStampToDate = ({ min, max }) => ({ from: min ? new Date(min * 1000) : null, to: max ? new Date(max * 1000) : null });

export const mapFacetName = (key) => {
  const values = {
    supercategoryName: 'Category',
    houseName: 'Seller',
    countryName: 'Seller Location',
    currencyCode: 'Currency',
    priceResult: 'Price',
    hasImage: 'Lots with images',
    artistName: 'Artist',
    currentBid: 'Bid',
  };
  return values[key] ? values[key] : 'Category';
};

const getArtistUserProperties = async (ids, oasHeaders, hostname) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-auth-token': oasHeaders['x-auth-token'],
    },
  };

  const data = { lotIDs: ids };

  try {
    const response = await axios.post(`https://${hostname}/api/members/userItemProperties`, data, options);
    return response.data;
  } catch (error) {
    return null;
  }
};

// This is to get the badging information from the lot user item properties for catalogs
const getLiveLotInfo = async (refs, oasHeaders, hostname, catalogRef) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-auth-token': oasHeaders['x-auth-token'],
    },
  };

  try {
    const response = await axios.post(`https://${hostname}/api/auctions/catalog/${catalogRef}/lots/IDs?size=${refs.length}`, refs, options);
    return response.data._embedded.auctionLotUserItemViewList;
  } catch (error) {
    logger.error(`Error fetching lots from Invaluable API [catalogRef=${catalogRef}]`, error);
    return null;
  }
};

export const getOasHeaders = async (hostname) => {
  try {
    const boulderResponse = await axios(`https://${hostname}/boulder/session-info`, { headers: {} });
    const { oasHeaders } = boulderResponse.data;
    return oasHeaders;
  } catch (error) {
    return null;
  }
};

export const convertCurrency = async (hostname, uniqueCurrencies, localCurrencyCode) => {
  if (localCurrencyCode !== 'default' && localCurrencyCode) {
    try {
      const response = await axios.post(
        `https://${hostname}/api/auctions/currency/convert`,
        // ex: ["AUD", "USD"] must be array
        uniqueCurrencies,
        {
          params: {
            to: localCurrencyCode,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      logger.error(
        'Error fetching currency conversions '
        + `[uniqueCurrencies=${JSON.stringify(uniqueCurrencies)}, `
        + `localCurrencyCode=${localCurrencyCode}]`,
        error
      );
      return [];
    }
  } else {
    return [];
  }
};

// function to filter array for only unique values
const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const getCurrencyConvertedResults = async (algoliaResults, hostname, localCurrencyCode, isLoggedIn) => {
  let conversions = [];
  const currencyCodes = algoliaResults.results[0].hits.map(hit => hit.currencyCode);
  const uniqueCurrencies = currencyCodes.filter(onlyUnique);
  conversions = await convertCurrency(hostname, uniqueCurrencies, localCurrencyCode);

  const results = {
    ...algoliaResults,
    results: algoliaResults.results.map((result) => {
      const updatedHits = result.hits.map((hit) => {
        let convertedPrice;
        // If any of these conditions are true do not proceed with converted price
        if (hit.currencyCode !== localCurrencyCode && localCurrencyCode !== 'default' && conversions.length > 0) {
          let conversionRate = conversions.filter(converted => converted.currencyCode === hit.currencyCode);
          conversionRate = conversionRate[0].conversionRate;
          /* When converting price to local we do not ever want decimals
          * if there is ever any fractional value always round up INV-8439 */
          convertedPrice = Math.ceil(hit.currentBid * conversionRate);
          convertedPrice = conversionRate && new Intl.NumberFormat('en-US', { style: 'currency', currency: localCurrencyCode, maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(convertedPrice);
        }
        const { priceResult, ...rest } = hit;
        if (isLoggedIn) return { ...hit, conversions, localCurrencyCode, convertedPrice };
        return { ...rest, conversions, localCurrencyCode, convertedPrice };
      });
      return {
        ...result,
        hits: updatedHits,
        conversions,
        localCurrencyCode,
      };
    }),
  };
  return results;
};

export const getNormalizedAlgoliaResult = async (algoliaResults, hostname, localCurrencyCode) => {
  const oasHeaders = await getOasHeaders(hostname);
  let userData = [];
  let conversions = [];
  const ids = algoliaResults.results[0].hits.map(hit => hit.objectID);
  const authToken = get(oasHeaders, 'x-auth-token', false);
  if (authToken) {
    const res = await getArtistUserProperties(ids, oasHeaders, hostname);
    if (res) {
      userData = res;
    }
  }

  const currencyCodes = algoliaResults.results[0].hits.map(hit => hit.currencyCode);
  const uniqueCurrencies = currencyCodes.filter(onlyUnique);
  conversions = await convertCurrency(hostname, uniqueCurrencies, localCurrencyCode);

  const results = {
    ...algoliaResults,
    results: algoliaResults.results.map((result) => {
      const updatedHits = result.hits.map((hit) => {
        let convertedPrice;
        // If any of these conditions are true do not proceed with converted price
        if (hit.currencyCode !== localCurrencyCode && localCurrencyCode !== 'default' && conversions.length > 0) {
          let conversionRate = conversions.filter(converted => converted.currencyCode === hit.currencyCode);
          conversionRate = conversionRate[0].conversionRate;
          /* When converting price to local we do not ever want decimals
          * if there is ever any fractional value always round up INV-8439 */
          convertedPrice = Math.ceil(hit.currentBid * conversionRate);
          convertedPrice = conversionRate && new Intl.NumberFormat('en-US', { style: 'currency', currency: localCurrencyCode, maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(convertedPrice);
        }

        if (userData && userData.length > 0) {
          const userItemProperties = userData.find(item => item.lotID.toString() === hit.objectID);
          return {
            ...hit,
            ...(userItemProperties ? { userItemProperties } : {}),
            conversions,
            localCurrencyCode,
            convertedPrice,
          };
        }
        const { priceResult, ...rest } = hit;
        if (oasHeaders && oasHeaders['x-auth-token']) return { ...hit, conversions, localCurrencyCode, convertedPrice };
        return { ...rest, conversions, localCurrencyCode, convertedPrice };
      });
      return {
        ...result,
        hits: updatedHits,
        conversions,
        localCurrencyCode,
      };
    }),
  };
  return results;
};

const hashCode = (string) => {
  let hash = 0;
  let i;
  let chr;

  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getCatalogAlgoliaResult = async (algoliaResults, hostname, catalogRef) => {
  const oasHeaders = await getOasHeaders(hostname);
  // create array to hold our userinformation properites info for each lot returned from getLiveInfo function
  let lotData = [];
  if (oasHeaders['x-auth-token']) {
    // Get Lot ID's in algolia results so we can make call for each lot
    const refs = algoliaResults.results[0].hits.map(hit => parseInt(hit.objectID, 10));
    const stringifiedRefs = JSON.stringify(refs);
    const newHash = JSON.stringify(hashCode(stringifiedRefs));
    const millisNow = Date.now();
    const getHashedRes = window.sessionStorage.getItem(newHash);
    const getExpiration = window.sessionStorage.getItem(`catRefExp${newHash}`);
    const resetExp = getExpiration < millisNow;

    // We set an Item in session storage so that this does not get called multiple times everytime we change a filter
    // Currently not working ideally
    if (getHashedRes === null || resetExp) {
      const liveInfo = await getLiveLotInfo(refs, oasHeaders, hostname, catalogRef);
      window.sessionStorage.setItem(newHash, JSON.stringify(liveInfo));
      window.sessionStorage.setItem(`catRefExp${newHash}`, millisNow + 10000);
      // If there are useritem properies to match to data put them into lotdata array
      if (liveInfo) {
        lotData = liveInfo;
      }
    } else {
      lotData = JSON.parse(getHashedRes);
    }
  }

  // Map over hits and match the userInformation Properties to their respective hit by Id and return combined results
  const results = {
    ...algoliaResults,
    results: algoliaResults.results.map((result) => {
      const updatedHits = result.hits.map((hit) => {
        if (lotData && lotData.length > 0) {
          const lotinfoProperties = lotData.find(item => item.ref === hit.lotRef);
          return {
            ...hit,
            ...(lotinfoProperties ? { lotinfoProperties } : {}),
          };
        }
        const { priceResult, ...rest } = hit;
        if (oasHeaders && oasHeaders['x-auth-token']) return { ...hit };
        return { ...rest };
      });
      return {
        ...result,
        hits: updatedHits,
      };
    }),
  };
  return results;
};

export const metersToMiles = (meters) => Math.round(meters / 1609.344);

export const algoliaSearchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, 'NO_KEY', {
  hosts: [
    {
      url: process.env.REACT_APP_ALGOLIA_API_URL,
      protocol: 'https',
      accept: CallEnum.Read,
    },
  ],
});

export const searchClient = (isBot) => ({
  ...algoliaSearchClient,
  search(requests) {
    const newRequests = requests.map((request) => {
      if (isBot) {
        request.params.analytics = false;
        request.params.enableABTest = false;
      }
      return request;
    });

    return algoliaSearchClient.search(newRequests);
  },
});

const doAlgoliaRequest = async (body) => {
  try {
    const algoliaResults = await algoliaSearchClient.search(
      body.requests,
    );
    const results = await getNormalizedAlgoliaResult(algoliaResults, window.location.hostname);
    return results;
  } catch (error) {
    console.error('algolia artist search error', error);
    const algoliaResults = await algoliaSearchClient.search(body.requests);
    const results = await getNormalizedAlgoliaResult(algoliaResults, window.location.hostname);
    return results;
  }
};

export const algoliaSearchClientNormalized = (isBot) => ({
  async search(queries) {
    const requests = queries.map((request) => {
      if (isBot) {
        request.params.analytics = false;
        request.params.enableABTest = false;
      }
      return request;
    });
    const body = { requests };
    const promiseCached = promiseCache.get(body);
    if (promiseCached) {
      return promiseCached;
    }
    const promise = doAlgoliaRequest(body);
    promiseCache.set(body, promise);
    return promise;
  },
  async searchForFacetValues(requests) {
    try {
      const results = await algoliaSearchClient.searchForFacetValues(requests);
      return results;
    } catch (error) {
      console.error('sffv artist page error', error);
      return {};
    }
  },
});
