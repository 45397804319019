import React from 'react';

import { Arrow } from './Arrow';
import { ARROW_DIRECTIONS } from './constants';

const COMMON_CONFIG = {
  slidesToScroll: 1,
  arrows: true,
  speed: 500,
  vertical: false,
  infinite: true,
  nextArrow: <Arrow direction={ARROW_DIRECTIONS.RIGHT} />,
  prevArrow: <Arrow direction={ARROW_DIRECTIONS.LEFT} />,
  accessibility: false,
};

const DESKTOP_CONFIG = {
  ...COMMON_CONFIG,
  slidesToShow: 4,
  draggable: false,
};

const TABLET_CONFIG = {
  ...COMMON_CONFIG,
  slidesToShow: 3,
  draggable: true,
  centerMode: false,
};

const MOBILE_CONFIG = {
  ...COMMON_CONFIG,
  slidesToShow: 2,
  draggable: true,
  centerMode: false,
};

const MOBILE_SM_CONFIG = {
  ...COMMON_CONFIG,
  slidesToShow: 1,
  draggable: true,
  centerMode: true,
};

export const DEFAULT_CAROUSEL_CONFIG = {
  DESKTOP_CONFIG,
  TABLET_CONFIG,
  MOBILE_CONFIG,
  MOBILE_SM_CONFIG,
};

export const NON_CIRCULAR_CAROUSEL_CONFIG = {
  DESKTOP_CONFIG: { ...DESKTOP_CONFIG, infinite: false },
  TABLET_CONFIG: { ...TABLET_CONFIG, infinite: false },
  MOBILE_CONFIG: { ...MOBILE_CONFIG, infinite: false },
  MOBILE_SM_CONFIG: { ...MOBILE_SM_CONFIG, infinite: false },
};
