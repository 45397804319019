import React, { Fragment } from 'react';
import { PropTypes, string, number, bool } from 'prop-types';
import { connectRefinementList } from 'react-instantsearch-dom';
import InnerList from './inner-list';
import { formatCurrency } from '../../../../utils/algolia-helper';

function OuterList({
  items,
  refine,
  title,
  pageType,
}) {
  return (
    <Fragment>
      {items.length > 0
    && (
    <Fragment>
      <h3>{ title }</h3>
      <div className={`refinment-list ${pageType !== 'catalog' ? 'filter-wrapper' : ''}`}>
        {items.length > 0 && (
        <div className="ais-RefinementList">
          <ul className="ais-RefinementList-list">
            {items.map((item) => {
              const displayName = decodeURIComponent(item.label);
              return (
                <Fragment key={item.label}>
                  <li
                    className={`ais-RefinementList-item ${item.isRefined && 'ais-RefinementList-item--selected'}`}
                  >
                    <label
                      className="ais-RefinementList-label"
                      htmlFor="form-check-input"
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={item.isRefined}
                        readOnly
                        onClick={(event) => {
                          event.preventDefault();
                          refine(item.value);
                        }}
                        aria-label={`${item.value} checkbox`}
                      />
                      <span
                        className="ais-RefinementList-labelText"
                        role="presentation"
                        onClick={(event) => {
                          event.preventDefault();
                          refine(item.value);
                        }}
                      >
                        {displayName}
                      </span>
                      <span className="ais-RefinementList-count">{ formatCurrency(item.count) }</span>
                    </label>
                  </li>
                  { item.label && <InnerList attribute={item.label} /> }
                </Fragment>
              );
            })}
          </ul>
        </div>
        )}
      </div>
    </Fragment>
    )}
    </Fragment>
  );
}

OuterList.defaultProps = {
  pageType: '',
};

OuterList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: string,
      isRefined: bool,
      count: number,
      value: PropTypes.arrayOf(string),
    })
  ).isRequired,
  refine: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  pageType: string,
};

export default connectRefinementList(OuterList);
