/* eslint-disable no-multi-str */
/*
** TODO - API_PATH should be /inv-react/api. Need to change some Nginx rules
** but will need Andy's help for that.
*/
const API_PATH = '/search/api';
const REF_PLACEHOLDER = '[ref]';
const loginSvcPrefix = (process.env.NODE_ENV === 'production') ? 'svc-session' : `${(process.env.NODE_ENV)}-svc`;
const isProd = process.env.NODE_ENV === 'production';

const constants = {
  REF_PLACEHOLDER,
  LOGIN_URL: '/login?redirectUrl=',
  STATIC_FILES_PATH: '/search/static',
  CF_API_PATH: '/lib/webServices/afObj.cfc',
  MY_KEYWORDS_PATH: '/my-account/keywords',
  MY_SUBSCRIPTIONS_PATH: '/services/mySubscriptions.cfm',
  PURCHASE_SUBSCRIPTIONS_PATH: '/invaluable/chooseSubPastPrices.cfm',
  ARTISTS_PER_PAGE: 100,
  ARTISTS_DEFAULT_INDEX_NAME:
    isProd
      ? 'artists_default_prod'
      : 'artists_default_stage',
  ARTISTS_ALPHA_INDEX_NAME:
    isProd
      ? 'artists_alpha_prod'
      : 'artists_alpha_stage',
  AUCTION_HOUSE_AlPHA_INDEX:
    isProd
      ? 'houses_alpha_prod'
      : 'houses_alpha_stage',
  AUCTION_HOUSE_DEFAULT_INDEX:
    isProd
      ? 'houses_default_prod'
      : 'houses_default_stage',
  CATALOG_UPCOMING_DEFAULT_INDEX_NOT_TIMED_PROD:
    'upcoming_lots_lotNumber_asc_prod',
  CATALOG_UPCOMING_DEFAULT_INDEX_NOT_TIMED_STAGE:
    'upcoming_lots_lotNumber_asc_stage',
  CATALOG_UPCOMING_DEFAULT_INDEX_TIMED_PROD:
    'upcoming_lots_closed_desc_lotNumber_asc_prod',
  CATALOG_UPCOMING_DEFAULT_INDEX_TIMED_STAGE:
    'upcoming_lots_closed_desc_lotNumber_asc_stage',
  CATALOG_PAST_DEFAULT_INDEX_PROD: 'archive_lotNumber_asc_prod',
  CATALOG_PAST_DEFAULT_INDEX_STAGE: 'archive_stage',
  DEFAULT_CURRENCIES: [
    {
      id: 'USD',
    },
    {
      id: 'EUR',
    },
    {
      id: 'AUD',
    },
    {
      id: 'GBP',
    },
    {
      id: 'CNY',
    },
  ],
};

const routes = {
  api: {
    ARTIST: `${API_PATH}/artist-bio`,
    ARTIST_INFO: '/api/auctions/artist/info',
    ARTIST_HOUSES: '/api/auctions/artist/associatedHouse',
    ARTIST_GENRES: '/api/auctions/artist/genres',
    ARTIST_FEATURED_LOTS: '/api/auctions/artist/featuredLots',
    ARTIST_NOTABLE_SOLD_LOTS: '/api/auctions/artist/notableLots',
    HANDLE_ARTIST_FOLLOW: `${API_PATH}/handle-artist-follow`,
    HANDLE_KW_FOLLOW: `${API_PATH}/handle-kw-follow`,
    INV_HEADER_DATA: `${API_PATH}/invheaderdata`,
    SEARCH_ITEM_SUGGESTIONS: `${API_PATH}/search-item-suggestions`,
    HANDLE_LIKE: '/api/members/',
    HANDLE_LIKE_AZ_WEB: `${API_PATH}/handle-like`,
    SEARCH: `${API_PATH}/search-results`,
    SEARCHV2_RESULTS: `${API_PATH}/search-resultsV2`,
    KEYWORDS_FOLLOWED: `${API_PATH}/keywords-followed`,
    SESSION_INFO: '/boulder/session-info',
    PDP_LOT: '/api/auctions/lot',
    PDP_LOT_V2: '/api/auctions/lot/',
    PDP_BIDDER_STATUS: '/api/auctions/catalog/bidderStatus',
    PDP_LOT_LIVE_INFO: '/api/auctions/lot/v2/liveInfo',
    PDP_WATCH_LOT: '/api/members/watchLot',
    PDP_UNWATCH_LOT: '/api/members/unwatchLot',
    PDP_WATCHER_COUNT: '/api/auctions/catalog/watcherCount?catalogRefs',
    PDP_GET_SELLER_INFO: '/app/v2/requestForInfo/lot',
    PDP_SELLER_MESSAGE: '/app/requestForInfo',
    PDP_GET_CONTACT_INFO: '/app/v2/user/editContactInfo',
    PDP_GET_STATES: '/app/states/',
    PDP_FOLLOW_SELLER: '/api/members/followSeller',
    PDP_FOLLOW_SELLER_COUNT: '/api/auctions/auction-houses/followCount',
    PDP_IS_SELLER_FOLLOWED: '/api/members/isSellerFollowed',
    PDP_REQUEST_FOR_APPROVAL_INIT: '/app/v2/requestForApprovalInit',
    PDP_REQUEST_FOR_APPROVAL: '/azweb/app/requestForApproval',
    PDP_MEMBER_BILLING_CARDS: '/azweb/app/v2/member/billing',
    PDP_UPDATE_BID_TOTAL: '/app/updateBidTotal',
    PDP_NOTIFY_ME: '/api/members/keywords',
    PDP_GET_SUBSCRIPTION_INFO: '/api/members/subscriptions',
    PDP_GET_ARTIST_INFO: '/api/auctions/artist/info',
    PDP_ORDER_BID_INIT: '/app/orderBidInit',
    PDP_ORDER_BID: '/app/orderBid',
    PDP_LOT_SMS_OPT_IN: '/app/v2/setSMSOptIn',
    PDP_CATALOG_SMS_OPT_IN: '/app/v2/setSMSOptIn/catalog',
    PDP_SUBMIT_RFA: '/app/requestForApproval',
    AUCTION_HOUSE_DETAILS: '/api/auctions/auction-houses/',
    UPCOMING_AUCTIONS: '/api/auctions/catalog/upcoming',
    LIVE_PARTICIPATION: '/api/auctions/catalog/liveParticipation',
    AH_IS_AUCTION_HOUSE_FOLLOWED: 'api/auctions/auction-houses/isSellerFollowed',
    AUCTION_HOUSE_PAST_ACTIONS: '/api/auctions/catalog/pastAuctions',
    AUCTION_HOUSE_POPULAR_ITEMS: '/api/auctions/auction-houses/popularHouseLots',
    AUCTION_HOUSE_POPULAR_ITEMS_LIVE_INFO: '/api/auctions/lot/v2/liveInfo',
    AUCTION_HOUSE_GET_INFO: '/app/requestForInfo/house/',
    USER_PROPERTIES: '/api/members/userItemProperties',
    PDP_GET_VIP_STATUS: '/api/members/heap-info',
    PDP_GET_VIP_AND_SUBSCRIPTION: '/api/members/subscriptions',
    USER_FOLLOWED_ARTIST_STATUS: '/api/members/followedArtist/status',
    SUPER_CATEGORY: '/app/supercategories',
    LOGIN: '/app/v2/member/login',
    BOULDER_LOGIN: '/boulder/login',
    UPDATE_TERMS: '/azweb/app/v2/user/updateTerms',
    SUBMIT_CREATE_ACCOUNT: '/app/v2/user/submitCreateAccount',
    GOOGLE_CLIENT_LOGIN: '/app/sso/googleSignIn',
    LAST_SEARCHED: '/api/members/lastSearched',
    REQUEST_FOR_APPROVAL: '/requestForApproval',
    EDIT_USER_DATA: 'v2/user/editAccount'
  },
  ARTIST: '/artist',
  ARTIST_DIRECTORY: '/artists/*',
  ARTMYN: '/artmyn',
  BIDDER: '/bidder',
  BLOG: '/blog',
  CATEGORY: '/category',
  HOMEPAGE: '/',
  CATALOG: '/catalog/:id',
  LANDING: '/myinvlanding',
  LOTSATAUCTION: '/lots-at-auction/',
  PARITY: '/parity/',
  REVIEWS: '/reviews',
  SOLDATAUCTION: '/sold-at-auction-prices/',
  SEARCH: '/search',
  NEW_SEARCH: '/new-search',
  PAST_SEARCH: '/past-search',
  FEATUREDTIMEDSALES: '/featured-timed-sales',
  PRESS: '/press',
  UPCOMING_AUCTIONS: '/auctions/',
  PDP_PAGE: '/v2/auction-lot/*',
  AUCTION_HOUSE_DETAILS: '/auction-house/*',
  PDP_V1_PAGE: '/auction-lot/*',
  PDP_V2_PAGE: '/v2/auction-lot/*',
  FEATURED_ARTISTS: '/artists/',
  AUCTION_HOUSE: '/auction-house',
  LAPADA: '/lapada',
  DISCOVER_ARTISTS: '/artists/discover',
  DISCOVER_CAMPAIGNS: '/collections',
  DOWNLOAD_APP: '/download-app',
  REQUEST_FOR_APPROVAL: '/v2/bidNow/reqForApproval',
  CATEGORIES: '/:categoryName/:categoryType-:categoryRef/'
};

// (bnikom) needed for boulder
const boulderConfig = {
  webSessionServiceEnv: `${loginSvcPrefix}.invaluable.com/azweb/app/`,
};

const types = {
  BOOLEAN: typeof true,
  NUMBER: typeof 1,
  OBJECT: typeof {},
  STRING: typeof 'string',
  UNDEFINED: typeof undefined,
};

/*
* (bnikom) 2 validation maps because one is for displaying the url string, and the url is for BE params
* (i.e. dateStart, dateEnd are only used in url string, but startDate - the full date string - is used only in BE params)
*/

const validationMaps = {
  SEARCH_BROWSER_PARAMS: {
    buyItNow: { type: types.NUMBER },
    country: { type: types.STRING },
    currency: { type: types.STRING },
    dateStart: { type: types.STRING },
    dateEnd: { type: types.STRING },
    minSaleDate: { type: types.STRING },
    maxSaleDate: { type: types.STRING },
    hasImage: { type: types.STRING },
    house: { type: types.STRING },
    keyword: { default: '', type: types.STRING },
    lotCategory: { type: types.STRING },
    lotSupercategory: { type: types.STRING },
    maxPrice: { type: types.STRING },
    minPrice: { type: types.STRING },
    page: { default: 0, type: types.NUMBER },
    postDate: { type: types.STRING },
    size: { default: 200, type: types.NUMBER },
    sort: { default: 'score,desc', type: types.STRING },
    state: { type: types.STRING },
    resultsPosted: { type: types.NUMBER },
    upcoming: { default: true, type: types.BOOLEAN },
  },
  SEARCH_API_PARAMS: {
    buyItNow: { type: types.NUMBER },
    country: { type: types.STRING },
    currency: { type: types.STRING },
    hasImage: { type: types.STRING },
    house: { type: types.STRING },
    keyword: { default: '', type: types.STRING },
    lotCategory: { type: types.STRING },
    lotSupercategory: { type: types.STRING },
    minBidCurrent: { type: types.STRING },
    maxPrice: { type: types.STRING },
    minPrice: { type: types.STRING },
    page: { default: 0, type: types.NUMBER },
    postDate: { type: types.STRING },
    price: { type: types.STRING },
    size: { default: 200, type: types.NUMBER },
    sort: { default: 'score,desc', type: types.STRING },
    startDate: { type: types.STRING },
    state: { type: types.STRING },
    resultsPosted: { type: types.NUMBER },
    upcoming: { default: true, type: types.BOOLEAN },
    withAll: { type: types.STRING },
    without: { type: types.STRING },
    withExact: { type: types.STRING },
    withOne: { type: types.STRING },
  },
  PAST_SEARCH_API_PARAMS: {
    minSaleDate: { type: types.STRING },
    maxSaleDate: { type: types.STRING },
    postDate: { type: types.STRING },
    priceResult: { type: types.STRING },
  },
};

// NO LONGER USING ALTERNATE URLS
// const getAlternateUrls = (req, fixTrailingSlash = false) => {
//   const hostnameNoSuffix = req.hostname.replace(/(\.com|\.co\.uk)$/, '');
//   const missingTrailingSlash = (req.url.slice(-1) !== '/');
//   const trailingSlash = (fixTrailingSlash && missingTrailingSlash) ? '/' : '';
//   return {
//     usUrl: `https://${hostnameNoSuffix}.com${req.url}${trailingSlash}`,
//     ukUrl: `https://${hostnameNoSuffix}.co.uk${req.url}${trailingSlash}`,
//   };
// };

const getImageEnv = (host = '') => {
  if (isProd && (host === 'www.invaluable.com' || !host)) {
    return 'image';
  }
  return 'stageimg';
};

const errorHandling = {
  logoSrc: 'https://image.invaluable.com/static/header/IN_Red32.svg',
  logoAltText: 'Invaluable',
  404: {
    title: 'Page not found',
    description:
      'The page you were trying to access cannot be found. \
        We apologize for the inconvenience. \
        Please try again or <a href="/" class="link">go to our home page.</a>',
  },
};

const config = {
  boulderConfig,
  constants,
  routes,
  types,
  validationMaps,
  // getAlternateUrls,
  getImageEnv,
  errorHandling,
};

module.exports = config;
