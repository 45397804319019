/* eslint-disable no-console */
/* eslint-disable array-callback-return */
import axios from 'axios';
import get from 'lodash/get';
import React from 'react';
import { Provider } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { StaticRouter, Route } from 'react-router-dom';
import { findResultsState } from 'react-instantsearch-dom/server';
import { Footer } from 'inv-common-components';

import { constants } from '../config';
import { ALGOLIA_HIT_BID_FIELDS_INDEXES } from './constants';
import logger from './middleware/logging/logger';

/**
 * Utility function which allows client side pages to set SEO properties.
 * @param {Object} seoDom The JSON object containing the following params
 * @param {string} seoDom.canonicalRef The canonicalRef for the link tag
 * @param {boolean} seoDom.hasRobots The meta robots - if true set to index follow,
 *  if false set to noindex follow.
 * @param {string} seoDom.ogUrl The meta property og:url content attribute
 * @param {string} seoDom.seoDescription The meta description and meta og:description
 *  content attribute
 * @param {string} seoDom.seoTitle The title value and meta og:title content attribute
 */
export const getClientSideSeoDom = ({
  canonicalRef = '',
  hasRobots = true,
  ogUrl = '',
  seoDescription = '',
  seoTitle = '',
}) => {
  const title = seoTitle ? `<title>${seoTitle}</title>` : '';
  const linkCanonical = canonicalRef ? `<link rel="canonical" href="${canonicalRef}" />` : '';
  const metaRobots = `<meta name="robots" content="${hasRobots ? 'index' : 'noindex'}, follow" />`;
  const metaDescription = seoDescription ? `<meta name="description" content="${seoDescription}" />` : '';
  const metaOgTitle = seoTitle ? `<meta property="og:title" content="${seoTitle}" />` : '';
  const metaOgDescription = seoDescription ? `<meta property="og:description" content="${seoDescription}" />` : '';
  const metaOgUrl = ogUrl ? `<meta property="og:url" content="${ogUrl}" />` : '';

  return title + linkCanonical + metaRobots + metaDescription + metaOgTitle + metaOgDescription + metaOgUrl;
};

export const getHeaderMarkUp = async ({ headers, hostname, layout = '', omitRobotTags = false }) => {
  const url = `https://${hostname}/api/markup/header-footer${layout === 'v4' ? '-v4' : ''}`;

  try {
    const response = await axios.get(url, {
      headers,
      params: { omitRobotTags }
    });
    response.data.footer = { markup: renderToString(<Footer />) };
    delete response.data.scripts.footer;
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching header markup from Invaluable API [url=${url}]`, { cause: error });
  }
};

export const getFooterMarkUp = () => {
  const footer = renderToString(
    <Footer />
  );
  return footer;
};

/**
 * Fetch string to generate <head> markup
 *
 * @param {Boolean} isProd
 */
export const getHeadMarkup = ({ robots = '' }) => ({ markup: `
<script defer>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-M47W3V');</script>

<meta charset="utf-8">
<link rel="shortcut icon" href="/favicon.ico" type="image/vnd.microsoft.icon" />
<link rel="apple-touch-icon-precomposed" sizes="57x57" href="/apple-touch-icon-57x57.png" />
<link rel="apple-touch-icon-precomposed" sizes="114x114" href="/apple-touch-icon-114x114.png" />
<link rel="apple-touch-icon-precomposed" sizes="72x72" href="/apple-touch-icon-72x72.png" />
<link rel="apple-touch-icon-precomposed" sizes="144x144" href="/apple-touch-icon-144x144.png" />
<link rel="apple-touch-icon-precomposed" sizes="60x60" href="/apple-touch-icon-60x60.png" />
<link rel="apple-touch-icon-precomposed" sizes="120x120" href="/apple-touch-icon-120x120.png" />
<link rel="apple-touch-icon-precomposed" sizes="76x76" href="/apple-touch-icon-76x76.png" />
<link rel="apple-touch-icon-precomposed" sizes="152x152" href="/apple-touch-icon-152x152.png" />
<link rel="icon" type="image/png" href="/favicon-196x196.png" sizes="196x196" />
<link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
<link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
<link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
<link rel="icon" type="image/png" href="/favicon-128.png" sizes="128x128" />
<meta name="application-name" content="&nbsp;"/>
<meta name="msapplication-TileColor" content="#FFFFFF" />
<meta name="msapplication-TileImage" content="/mstile-144x144.png" />
<meta name="msapplication-square70x70logo" content="/mstile-70x70.png" />
<meta name="msapplication-square150x150logo" content="/mstile-150x150.png" />
<meta name="msapplication-wide310x150logo" content="/mstile-310x150.png" />
<meta name="msapplication-square310x310logo" content="/mstile-310x310.png" />
<meta property="fb:admins" content="1062050977" />


<meta name="apple-itunes-app" content="app-id=944415329">
<meta name="google-play-app" content="app-id=com.invaluable.invaluable&referrer=utm_source%3DInvaluable%20Header%20CTA%26utm_medium%3Dwebsite%26utm_campaign%3DGoogle%20Play%20App%20Download">


<link rel="stylesheet" rel="preload" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<link rel="stylesheet" rel="preconnect" data-href="https://use.typekit.net/dwm6qys.css?display=swap" data-optimized-fonts="true"/>
  
${robots}
  
<meta name="viewport" content="width=device-width, initial-scale=1">
  
<link rel="manifest" href="/manifest.json">
<meta name="theme-color" content="#fff">

<meta name="referrer" content="origin">
  ` });

export const getArtistUserProperties = async (ids, oasHeaders, hostname) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-auth-token': oasHeaders['x-auth-token'],
    },
  };

  const data = { lotIDs: ids };

  try {
    const response = await axios.post(`https://${hostname}/api/members/userItemProperties`, data, options);
    return response.data;
  } catch (error) {
    logger.error(`Error fetching user item properties from Invaluable API [lotIDs=${ids}]`, error);
    return null;
  }
};

export const getMemberInfo = async (cookies, hostname) => {
  try {
    const boulderResponse = await axios(`https://${hostname}/boulder/session-info`, { headers: { cookie: cookies } });
    return boulderResponse.data;
  } catch (error) {
    logger.error('Error fetching member info from Invaluable API', error);
    return null;
  }
};

export const getOasHeaders = async (cookies, hostname) => {
  const { oasHeaders } = await getMemberInfo(cookies, hostname);
  return oasHeaders;
};

export const getNormalizedAlgoliaResult = async (algoliaResults, cookies, hostname) => {
  const oasHeaders = await getOasHeaders(cookies, hostname);
  let userData = [];
  if (oasHeaders && oasHeaders['x-auth-token']) {
    const ids = algoliaResults.results[0].hits.map(hit => hit.objectID);
    const res = await getArtistUserProperties(ids, oasHeaders, hostname);
    if (res) {
      userData = res;
    }
  }

  const results = {
    ...algoliaResults,
    results: algoliaResults.results.map((result) => {
      const updatedHits = result.hits.map((hit) => {
        if (userData && userData.length > 0) {
          const userItemProperties = userData.find(item => item.lotID.toString() === hit.objectID);
          return {
            ...hit,
            ...(userItemProperties ? { userItemProperties } : {}),
          };
        }
        const { priceResult, ...rest } = hit;
        if (oasHeaders && oasHeaders['x-auth-token']) return { ...hit };
        return { ...rest };
      });
      return {
        ...result,
        hits: updatedHits,
      };
    }),
  };
  return results;
};

// This is to get the badging information from the lot user item properties for catalogs
const getLiveLotInfo = async (refs, oasHeaders, hostname, catalogRef) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-auth-token': oasHeaders['x-auth-token'],
    },
  };

  const url = `https://${hostname}/api/auctions/catalog/${catalogRef}/lots/IDs?size=${refs.length}`;

  try {
    const response = await axios.post(url, refs, options);
    return response.data._embedded.auctionLotUserItemViewList;
  } catch (error) {
    logger.error(`Error fetching live lot info from Invaluable API (POST ${url})`, error);
    return null;
  }
};

export const getCatalogAlgoliaResult = async (algoliaResults, cookies, hostname, catalogRef) => {
  const oasHeaders = await getOasHeaders(cookies, hostname);
  // create array to hold our userinformation properites info for each lot returned from getLiveInfo function
  let lotData = [];
  if (oasHeaders['x-auth-token']) {
    // Get Lot ID's in algolia results so we can make call for each lot
    const refs = algoliaResults.results[0].hits.map(hit => parseInt(hit.objectID, 10));
    const liveInfo = await getLiveLotInfo(refs, oasHeaders, hostname, catalogRef);
    if (liveInfo) {
      lotData = liveInfo;
    }
  }

  // Map over hits and match the userInformation Properties to their respective hit by Id and return combined results
  const results = {
    ...algoliaResults,
    results: algoliaResults.results.map((result) => {
      const updatedHits = result.hits.map((hit) => {
        if (lotData && lotData.length > 0) {
          const lotinfoProperties = lotData.find(item => item.ref === hit.lotRef);
          return {
            ...hit,
            ...(lotinfoProperties ? { lotinfoProperties } : {}),
          };
        }
        const { priceResult, ...rest } = hit;
        if (oasHeaders && oasHeaders['x-auth-token']) return { ...hit };
        return { ...rest };
      });
      return {
        ...result,
        hits: updatedHits,
      };
    }),
  };
  return results;
};

const getSubscriptionStatus = async (hostname, headers) => {
  const url = `https://${hostname}/api/members/subscriptions`;
  try {
    const subStatusResponse = await axios.get(url, { headers });
    return subStatusResponse.data.subscriptions;
  } catch (error) {
    logger.error(`Error fetching subscription status from Invaluable API (GET ${url})`);
    return [];
  }
};

const getExtendedLotData = async (headers, hostname, hits) => {
  const lotRefs = hits.map(hit => hit.lotRef);
  const autHeaders = {
    'Content-Type': 'application/json',
    channel: 'invaluable',
    'x-auth-token': get(headers, "['x-auth-token']", ''),
  };
  try {
    const url = `https://${hostname}/api/auctions/lot/v2/liveInfos`;
    const lotInfo = await axios.post(url, lotRefs, { autHeaders });
    return lotInfo.data;
  } catch (error) {
    logger.error('Error fetching information from liveInfo API', error);
    return null;
  }
};

// TODO: Move this function to a utility file and use it everywhere we are getting similar data from Algolia
const removePII = (hit) => {
  // Check if the hit is defined and not null
  if (hit && typeof hit === 'object') {
    // Remove PII fields (e.g., hit, watched, winner)
    delete hit.bids;
    delete hit.watched;
    delete hit.winner;
    // Add additional PII fields to be removed if needed
  } else {
    // Handle the case where the input is not a valid object
    console.error('Invalid hit object');
  }
};

export const getBadgeStatus = async (algoliaResults, cookies, hostname) => {
  const headers = await getMemberInfo(cookies, hostname);
  const indexName = algoliaResults.results[0].index;
  const isCatalogIndex = [constants.CATALOG_UPCOMING_DEFAULT_INDEX_TIMED_STAGE, constants.CATALOG_UPCOMING_DEFAULT_INDEX_TIMED_PROD].includes(algoliaResults.results[0].index);

  if (isCatalogIndex) {
    // get results and return array with extended lot times if applicable
    const extendedLotResults = await getExtendedLotData(headers.oasHeaders, hostname, algoliaResults.results[0].hits);
    await extendedLotResults.map((lot) => {
      if (lot.isExtended === true) {
        algoliaResults.results[0].hits.map((hit) => {
          if (lot.lotRef === hit.lotRef) {
            hit.secondsToClose = lot.secondsToClose;
            hit.lotinfoProperties = {
              isExtended: true,
              secondsToClose: lot.secondsToClose,
            };
          }
        });
      }
    });
  }

  /*
    Hide prices of all past lots for users without a subscription on the search page,
    and display prices on the catalog page for past lots from the last 1 year.
    This implementation aligns with the requirements specified by product team for A/B testing.
    Ticket: INV-8432.
  */
  let catalogCutoffDateUTC = null;
  // For Catalog page hardcord 1 year past lots data
  if (indexName !== 'archive_prod') {
    const yearInUnix = 31536000000;
    catalogCutoffDateUTC = (Date.now() - yearInUnix) / 1000;
  }
  if (get(headers, 'user.userID', false)) {
    const userID = headers.user.userID;
    const subscriptions = await getSubscriptionStatus(hostname, headers.oasHeaders, userID);

    await subscriptions.map((sub) => {
      // Convert subscription date to seconds
      const subDateSeconds = sub.catalogCutoffDateUTC / 1000;
      if (catalogCutoffDateUTC === null || subDateSeconds < catalogCutoffDateUTC) {
        catalogCutoffDateUTC = subDateSeconds;
      }
    });
    // map over our results array to insert new data into hits
    const newHits = await Promise.all(algoliaResults.results[0].hits.map(async (hit) => {
      const dateTimeUTCUnix = hit.dateTimeUTCUnix;
      const displayPrices = (catalogCutoffDateUTC !== null && dateTimeUTCUnix > catalogCutoffDateUTC);
      const winningBidder = (hit.winner === userID);
      const watched = (hit.watched && hit.watched.includes(userID));

      let hasBid = false;
      let pendingBid = false;
      let highBidder = false;
      let myMax = 0;
      let isConciergeBid = false;

      // if there are no bids in object do nothing
      if (hit.bids) {
        hit.bids.forEach((bid) => {
          const splitBid = bid.split('|');
          const memberID = splitBid[ALGOLIA_HIT_BID_FIELDS_INDEXES.memberId];
          const bidStatus = splitBid[ALGOLIA_HIT_BID_FIELDS_INDEXES.orderBidStat];
          const bidAmount = parseInt(splitBid[ALGOLIA_HIT_BID_FIELDS_INDEXES.maxBidAmount], 10);
          const bidRecordStatus = splitBid[ALGOLIA_HIT_BID_FIELDS_INDEXES.recordStat];

          // Check conditions of bids
          if (memberID === userID.toString()) {
            hasBid = true;
            switch (bidStatus) {
              case 'a':
                myMax = bidAmount;
                break;
              case 'p':
                pendingBid = true;
                myMax = bidAmount;
                break;
              default:
                console.info('No match found with bidStatus');
            }
            if (bidRecordStatus === 'c') {
              isConciergeBid = true;
            }
          }
        });

        // We will find the first absentee bid after concierge bids as it is the highest absentee bid.
        const highestAbsenteeBid = hit.bids.find(bid => bid.split('|')[ALGOLIA_HIT_BID_FIELDS_INDEXES.recordStat] !== 'c');

        /*
          For calculating if concierge bid is high bidder or outbid, we constructed the condition based on the following checks:-
            1. If concierge bid and absentee bid was placed on this lot, we check if current user max amount is greater than
            current bid amount.
            2. If no absentee bid was placed we simply check if it is concierge bid to mark it high bidder or outbid.
        */
        const isConciergeHighBidder = (isConciergeBid && highestAbsenteeBid !== undefined)
          ? myMax > hit?.currentBid
          : isConciergeBid;

        if (highestAbsenteeBid || isConciergeHighBidder) {
          const splitBid = highestAbsenteeBid?.split('|');
          const memberID = splitBid?.[ALGOLIA_HIT_BID_FIELDS_INDEXES.memberId];
          const bidStatus = splitBid?.[ALGOLIA_HIT_BID_FIELDS_INDEXES.orderBidStat];

          // Check if it is ours highest absentee bid and in status 'a' for accepted or it could be high concierge bidder.
          if ((bidStatus === 'a' && memberID === userID.toString() && !isConciergeBid) || isConciergeHighBidder) {
            highBidder = true;
          }
        }
      }

      // Omit the bids, winner and watched properties so they cannot be used by malicious characters
      removePII(hit);
      return {
        ...hit,
        lotinfoProperties: {
          ...hit.lotinfoProperties,
          highBidder,
          hasBid,
          pendingBid,
          watched,
          myMax: myMax !== 0 ? myMax : null,
          winningBidder,
          displayPrices,
          isConciergeBid,
        },
      };
    }));

    const results = {
      ...algoliaResults,
      results: algoliaResults.results.map(result => ({
        ...result,
        hits: newHits,
      })),
    };
    return results;
  }

  // If the user is not logged in we still need to omit bids, watched and winner properties for security reasons
  const newHitsNoLogin = await algoliaResults.results[0].hits.map((hit) => {
    // Omit the bids, winner and watched properties so they cannot be used by malicious characters
    removePII(hit);
    return {
      ...hit,
    };
  });
  const resultsNoLogin = {
    ...algoliaResults,
    results: algoliaResults.results.map(result => ({
      ...result,
      hits: newHitsNoLogin,
    })),
  };
  return resultsNoLogin;
};

export const removeHeadTags = (head, robotsStatus = false) => {
  const removedTagsHead = head;
  let markup = head.markup
    .replace('<meta charset="utf-8">', '')
    .replace('<link rel="shortcut icon" href="https://www.invaluable.com/favicon.ico" type="image/vnd.microsoft.icon" />', '')
    .replace('<title></title>', '')
    .replace('<meta property="og:title" content=""/>', '')
    .replace('<meta property="og:description" content="" />', '')
    .replace('<meta property="og:url" content="" />', '')
    .replace('<meta name="description" content="" id="start-meta">', '')
    .replace('<link rel="stylesheet" href="">', '')
    .replace('<title>\n', '')
    .replace('    \n', '')
    .replace('</title>\n', '');

  if (robotsStatus === 'no_index') {
    markup = head.markup.replace(
      '<meta name="robots" content="index, follow">',
      '<meta name="robots" content="noindex, nofollow">'
    );
  }
  if (robotsStatus === true) {
    const robotsStart = head.markup.indexOf('<meta name="robots');
    const robotsEnd = head.markup.indexOf('>', robotsStart);
    const robotString = head.markup.slice(robotsStart, robotsEnd + 1);
    markup = head.markup.replace(robotString, '');
  }
  removedTagsHead.markup = markup;
  return removedTagsHead;
};

/**
 * Wraps the component to use in algolia findResultsState in Provider, for server side
 * rendering of the algolia search results
 * @param {*} Component
 * @param {*} store
 * @param {*} componentProps
 * @returns Algolia search state
 */
export const fetchAlgoliaResultsStateServerSide = async (Component, store, componentProps) => {
  try {
    const resultsState = await findResultsState(props => (
      <Provider store={store}>
        <StaticRouter
          location={componentProps.url}
        >
          <Route
            path={componentProps.path}
            component={routeProps => (
              <Component {...routeProps} {...props} />
            )}
          />
        </StaticRouter>
      </Provider>
    ), componentProps);

    return resultsState;
  } catch (error) {
    logger.error(`Algolia SSR fetch error [store=${store}]`, error);

    return {
      metadata: [],
      rawResults: [],
    };
  }
};

/**
 * Extracts location information from a URL string
 * @param {string} url - The URL string to parse
 * @param {string} hostname - The hostname to include in the location object
 * @returns {Object} An object with properties `pathname`, `hostname`, and `search` extracted from the URL
 */
export const parseLocationFromURL = (url, hostname) => {
  const [pathname, searchParams] = url.split('?');
  const search = searchParams ? `?${searchParams}` : '';
  return {
    pathname,
    hostname,
    search,
  };
};

/**
 *
 * @param {Object} req -req object
 * @returns {Boolean} return a boolean state, isMobile, based on whether the application is accessed from a mobile device or not.
 */
export const getIsMobileUserAgent = (req) => {
  const userAgent = req.headers['user-agent'];
  const isMobile = Boolean(userAgent.match(
    /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
  ));

  return isMobile;
};

// export route specific util files here, Add generic routeUtils above this line
export * from './utils/artistsRoutesUtils';
