import { RESTFulService } from '../rest-client/restful-service';
import { routes } from '../../../config';

class INVCommonService extends RESTFulService {
  getSessionInfo = async (cookie, hostname) => {
    const boulderSessionUrl = `https://${hostname}${routes.api.SESSION_INFO}`;
    let params = {};

    if (cookie && typeof window === 'undefined') {
      params = { options: { headers: { cookie } } };
    }

    return this.get(boulderSessionUrl, params);
  };

  getFollowThisArtistStatus = async (artistRef, cookie, hostname) => {
    const url = `https://${hostname}${routes.api.HANDLE_ARTIST_FOLLOW}?artistRef=${artistRef}&toggle=false`;
    const params = { options: { headers: { cookie } } };

    return this.get(url, params);
  };

  getUserFollowedArtistsStatus = async (cookie, hostname, authToken, artistRefs) => {
    const url = `https://${hostname}${routes.api.USER_FOLLOWED_ARTIST_STATUS}`;
    const params = { data: artistRefs, options: { headers: { cookie, 'x-auth-token': authToken } } };

    return this.post(url, params);
  };

  setFollowThisArtistStatus = async (artistRef, hostname) => (
    this.get(`https://${hostname}${routes.api.HANDLE_ARTIST_FOLLOW}?artistRef=${artistRef}&toggle=true`)
  );

  postWatchUnWatchLot = async (itemRef, action, authToken) => {
    const params = { options: { headers: { 'x-auth-token': authToken } } };
    const url = `${routes.api.HANDLE_LIKE}${action}/${itemRef}`;
    return this.get(url, params);
  };
}

export default new INVCommonService();
