import React from 'react';
import { func, shape, arrayOf } from 'prop-types';
import { connectRefinementList } from 'react-instantsearch-dom';
import { formatCurrency } from '../../../../utils/algolia-helper';

function InnerList({
  items,
  refine,
}) {
  return (
    <>
      {items.length > 0
      && (
      <div
        className="ais-RefinementList"
        style={{ marginLeft: '15px' }}
      >
        <ul className="ais-RefinementList-list">
          {items.map((item) => {
            const displayName = decodeURIComponent(item.label);
            return (
              <li
                key={item.label}
                className={`ais-RefinementList-item ${item.isRefined && 'ais-RefinementList-item--selected'}`}
              >
                <label
                  className="ais-RefinementList-label"
                  htmlFor="form-check-input"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    readOnly
                    checked={item.isRefined}
                    onClick={() => refine(item.value)}
                    aria-label={`${item.value} checkbox`}
                  />
                  <span
                    role="presentation"
                    onClick={() => refine(item.value)}
                  >
                    {displayName}
                  </span>
                  <span className="ais-RefinementList-count">{ formatCurrency(item.count) }</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
      )}
    </>
  );
}

InnerList.propTypes = {
  items: arrayOf(shape({})).isRequired,
  refine: func.isRequired,
};

export default connectRefinementList(InnerList);
