import React from 'react';
import { UncontrolledPopover } from 'reactstrap';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

function UserPopover({ userCounts }) {
  const isVip = useSelector((state) => get(state, 'invReactCommon.isVIP', false));

  return (
    <UncontrolledPopover
      placement="bottom"
      target="userOptionsBtn"
      trigger="focus hover"
      popperClassName="nav-popover"
      innerClassName="popover-body"
      /*
      * This is a hack to get the popover to show up on the bottom of the container
      known bug with reactstrap https://github.com/reactstrap/reactstrap/issues/1613
      */
      flip={false}
      boundariesElement="window"
    >
      <div className="my-acct-nav menu-options menu-user-options">
        <div className="border-bottom mt-2 pb-2">
          <a
            id="myInvHomePartial"
            href="/my-account/home"
          >My Invaluable
          </a>
        </div>
        <div className="border-bottom mt-2 pb-2">
          <ul className="menu-options-list list-unstyled mb-0">
            <li><a href="/my-account/bids"> <span
              id=""
              className="inline-block counts"
            />Bids
              {userCounts.ACTIVE_BIDS > 0 && ` (${userCounts.ACTIVE_BIDS})`}
            </a>
            </li>
            <li><a href="/my-account/auctions"> <span
              id=""
              className="inline-block counts"
            />Auctions{userCounts.REGISTERED_AUCTIONS > 0 && ` (${userCounts.REGISTERED_AUCTIONS})`}
            </a>
            </li>
            <li>
              <a href="/my-account/purchases"> <span
                id=""
                className="inline-block counts"
              />Purchases
                {userCounts.WON_LOTS > 0 && ` (${userCounts.WON_LOTS})`}
              </a>
            </li>
            {isVip
              && (
                <li>
                  <a href="/inv/vip/" target="_blank" rel="noopener noreferrer">
                    <img
                      width="20"
                      src="https://www.invaluable.com/content/images/VIPBidder_Paddle.svg"
                      alt="VIP Badge"
                    />&nbsp;VIP Benefits
                  </a>
                </li>
              )}
          </ul>
        </div>
        <div className="border-bottom mt-2 pb-2">
          <ul className="list-unstyled mb-0">
            <li className="no-wrap">
              <a href="/my-account/saved-items"><i className="fa fa-heart mr-1" />Saved Items <span
                id="count-saved"
                className="counts"
              />{userCounts.WATCHED_LOTS > 0 && ` (${userCounts.WATCHED_LOTS})`}
              </a>
            </li>
            <li>
              <span className="disabled disabled-link">Following</span>
            </li>
            <li className="ml-3 no-wrap"><a href="/my-account/keywords">Keywords
              <span
                id="count-keywords"
                className="counts"
              />{userCounts.KEYWORD_ALERTS > 0 && ` (${userCounts.KEYWORD_ALERTS})`}
            </a>
            </li>
            <li className="ml-3 no-wrap"><a href="/my-account/artists">Artists<span
              id="count-artists"
              className="counts"
            />{userCounts.ARTIST_ALERTS > 0 && ` (${userCounts.ARTIST_ALERTS})`}
            </a>
            </li>
            <li className="ml-3 no-wrap"><a href="/my-account/sellers">Sellers<span
              id="count-sellers"
              className="counts"
            />{userCounts.FOLLOWING_SELLERS_COUNT > 0 && ` (${userCounts.FOLLOWING_SELLERS_COUNT})`}
            </a>
            </li>
            <li className="ml-3 no-wrap"><a href="/my-account/categories">Categories<span
              id="count-categories"
              className="counts"
            />{userCounts.CATEGORY_ALERTS > 0 && ` (${userCounts.CATEGORY_ALERTS})`}
            </a>
            </li>
          </ul>
        </div>
        <div className="mt-2 border-bottom">
          <ul className="list-unstyled mb-1">
            <li>
              <a id="heap-tag-id" className="user-menu-tag" href="/my-account/digital-gallery">Digital Gallery</a>
            </li>
          </ul>
        </div>
        <div className="border-bottom mt-2 pb-2">
          <ul className="list-unstyled mb-0">
            <li><a href="/my-account/message-center">Messages
              <span
                id="count-messages"
                className="counts"
              >{userCounts.UNREAD_MESSAGES_COUNT > 0 && ` (${userCounts.UNREAD_MESSAGES_COUNT})`}
              </span>
            </a>
            </li>
            <li><a href="/inv/help/">Help</a></li>
            <li><a href="/my-account/profile">Edit Profile</a></li>
            <li><a href="/my-account/email-preferences">Notification &amp;<br /> Email Preferences</a></li>
            <li><a href="/myInvaluable/mySubscriptions.cfm">Subscriptions</a></li>
            <li><a href="/my-account/payment-options">Payment Options</a></li>
          </ul>
        </div>
        <div className="mt-2">
          <ul className="list-unstyled">
            <li><a
              className="logout-link"
              href="/logout.cfm"
            >Sign Out
            </a>
            </li>
            <li />
          </ul>
        </div>
      </div>
    </UncontrolledPopover>
  );
}

export default UserPopover;
