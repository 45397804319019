export const MOBILE_MEDIA_QUERY = '(max-width: 767px)';
export const MOBILE_SM_MEDIA_QUERY = '(max-width: 575px)';
export const TABLET_MEDIA_QUERY = ('(min-width: 768px) and (max-width: 991px)');
export const DESKTOP_MEDIA_QUERY = '(min-width: 992px)';
export const LOGIN_MODAL_ID = 'loginLink';
export const MODAL_TRANSITION_TIME_OUT_MS = 500;

export const ERROR_MESSAGES = {
  WATCH_LOT_ERROR: 'We are unable to save this item at this time. Please try again later or contact Customer Care any time at 617-746-9800',
  UN_WATCH_LOT_ERROR: 'We are unable to remove this item at this time. Please try again later or contact Customer Care any time at 617-746-9800',
};

export const WATCH_UNWATCH_LOT_ACTIONS = {
  WATCH_LOT: 'watchLot',
  UNWATCH_LOT: 'unwatchLot',
};
export const TIME_IN_MS_MAPPING = {
  ONE_SECOND_IN_MS: 1000,
  ONE_MINUTE_IN_MS: 60 * 1000,
  FIVE_MINUTES_IN_MS: 5 * 60 * 1000,
  ONE_HOUR_IN_MS: 60 * 60 * 1000,
  ONE_DAY_IN_MS: 24 * 60 * 60 * 1000,
  ONE_WEEK_IN_MS: 24 * 60 * 60 * 1000 * 7,
};

export const IMAGE_CDN_ARTIST_RELATIVE_URL = 'static/invaluable/artist/';

export const STATIC_POPULAR_ARTISTS = [
  {
    artistRef: 'GKWJVL6LVH',
    artistId: '10873',
    redirectURL: '/artist/warhol-andy-gkwjvl6lvh/',
    name: 'Andy Warhol',
    imageURL: 'andy_warhol.jpeg',
  },
  {
    artistRef: 'EU429SACMA',
    artistId: '11240',
    redirectURL: '/artist/haring-keith-eu429sacma/',
    name: 'Keith Haring',
    imageURL: 'keith_haring.jpg',
  },
  {
    artistRef: 'NVDDDUIINB',
    artistId: '26505',
    redirectURL: '/artist/picasso-pablo-nvddduiinb/',
    name: 'Pablo Picasso',
    imageURL: 'pablo_picasso.jpeg',
  },
  {
    artistRef: '0C0BOW85NR',
    artistId: '12085',
    redirectURL: '/artist/lichtenstein-roy-0c0bow85nr/',
    name: 'Roy Lichtenstein',
    imageURL: 'roy_lichtenstein.jpeg',
  },
  {
    artistRef: '9CHKGUV69J',
    artistId: '23046',
    redirectURL: '/artist/dali-salvador-9chkguv69j/',
    name: 'Salvador Dalí',
    imageURL: 'salvador_dali.jpeg',
  },
  {
    artistRef: 'ZW36N0FQCK',
    artistId: '22802',
    redirectURL: '/artist/chagall-marc-zw36n0fqck/',
    name: 'Marc Chagall',
    imageURL: 'marc_chagall.jpeg',
  },
  {
    artistRef: 'C6H5RPDFGZ',
    artistId: '22893',
    redirectURL: '/artist/miro-joan-c6h5rpdfgz/',
    name: 'Joan Miró',
    imageURL: 'joan_miro.jpeg',
  },
  {
    artistRef: 'FC6N18PLE0',
    artistId: '25861',
    redirectURL: '/artist/kentridge-william-joseph-fc6n18ple0/',
    name: 'William Joseph Kentridge',
    imageURL: 'william_kentridge.jpeg',
  },
  {
    artistRef: '30O5P2CKOB',
    artistId: '26334',
    redirectURL: '/artist/mucha-alphonse-maria-30o5p2ckob/',
    name: 'Alfons Mucha',
    imageURL: 'alfons_mucha.jpeg',
  },
  {
    artistRef: 'Z45RU55Y99',
    artistId: '24806',
    redirectURL: '/artist/buffet-bernard-z45ru55y99/',
    name: 'Bernard Buffet',
    imageURL: 'bernard_buffet.jpeg',
  },
  {
    artistRef: 'ALRL8L8W7B',
    artistId: '44101',
    redirectURL: '/artist/lalique-rene-alrl8l8w7b/',
    name: 'Rene Lalique',
    imageURL: 'rene_lalique.jpeg',
  },
  {
    artistRef: '11ANRJF4FD',
    artistId: '2498',
    redirectURL: '/artist/chase-william-merritt-11anrjf4fd/',
    name: 'William Merritt Chase',
    imageURL: 'william_merritt_chase.jpeg',
  },
];

export const INVALUABLE_BASEURL_PROD = 'https://www.invaluable.com';

export const ASYNC_ACTION_STATUS_TYPE = {
  IDLE: 'idle',
  LOADING: 'loading',
  COMPLETE: 'complete',
  FAILED: 'failed',
};
